:root {
    --mainColor: #30447e;
    --secondColor: #2f4b86;
    --thirdColor: #c04522;
}

body{
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif !important;

}

.acceptOffer{
    font-size: 12px;
}

#payButton:hover{
    cursor: pointer;
}


.tableCenter{
    text-align: center;
    margin: 20px auto;
}

#payButton{
    margin-right: 6px;
}

.acceptOffer{
    padding: 20px 0;
}

.logoFooter{
    width: 200px;
}

.App{
    background-color: #f6f6f6;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.wrapper{
    max-width: 1200px;
    margin: 0 auto;
}
.topbarDesktop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}
.logo{
    width:  130px;
}

.mainPageSearchBlockInsideH3{
    color: var(--mainColor);
}
.accept-cookies-wrapper {
    display: flex;
    justify-content: center;
}
#accept-cookies {
    width: 226px;
    height: 42px;
    color: #ffffff;
    background-color: var(--thirdColor);
    border: unset;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 700;
}
.thankYouBlock h1, .thankYouBlock h2{
    text-align: center;
    color: #0dbd3a !important;
    font-weight: 600;
}
.cookieModalClass{
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 500px;
    background: #ffffff;
    padding: 20px;
    margin: 0 auto;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px 6px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 6px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 6px rgba(34, 60, 80, 0.2);
    color: #3d3d3d;
}

.thankYouBlock > div{
    display: flex;
    justify-content: center;
    padding: 30px 0;
}
.thankYouBlock > div > a{
    text-decoration: none;
}

.primaryButton{
    background-color:  #007bff !important;;
}
.topbarFullMenu{
    list-style-type: none;
    display: flex;
    align-items: center;
    margin-top: 21px;
}
#rfs-btn{
    height: 34px;
}
.redButton:hover{
color: #FFFFFF;
}
.lastTrackerInformation{
    margin-top: 16px;
    padding: 0 10px;
}

.topbarFullMenu li{
    margin-right: 10px;
}
.topbarFullMenu a, .contactLink a{
   text-decoration: none;
    color: #26222f;
    font-weight: 500;
}

.topbarFullMenu a:hover, .contactLink a:hover{
    color: var(--thirdColor);
}

.legalInformation{
    display: flex;
    justify-content: center;
}

.legalInformation > div{
    border: 1px solid #e6e8e9;
    background-color: #fafafa;
    padding: 20px;
    border-radius: 10px;
}

.topbarDesktop > div{
    display: flex;
    align-items: center;

}
.topbarDesktop > div:last-child > div:first-child {
    padding-bottom: 5px;
}

#rfs-btn{
    width: 145px;
}

.singIn{
    margin-right:15px;
}

.singIn:hover{
    cursor: pointer;
}

.topbarUser{
    display: flex;
    align-items: center;
    margin-right: 25px;
    position: relative;
    padding: 4px 10px;
    font-family: inherit;
    color: #4d4d4d;
    border: thin solid rgba(77, 77, 77, 0.3);
    border-radius: 4px;
    background: transparent;
}

.topbarUser:hover{
    cursor: pointer;
}

.topbarUser img{
    border-radius: 50%;
    margin-right: 10px;

    width: 30px;
}

.topUserMenu{
    position: absolute;
    margin-top: 140px;
    z-index: 999999;
    border: 1px solid #bdbbbb;
    border-radius: 3px;
    background: #ffffff;
  right: 0;
    left: 0;
    padding: 8px 0;
    max-height: 180px;
    overflow: auto;
    color: #000000;
}

.topbarUser:after{
    border-top: 5px solid #4d4d4d;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 0;
    content: " ";
    width: 0;
    height: 0;
    display: inline-block;
    margin-left: 5px;
}

.topbarUser[aria-expanded="true"]:after{
    border-top: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #4d4d4d;
}

.topUserMenu ul, .topUserMenuMobile ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.topUserMenu ul li, .topUserMenuMobile ul li{
    padding: 2px 10px;
    cursor: pointer;

    white-space: nowrap;
}

.topUserMenu ul li:hover, .topUserMenuMobile ul li:hover{
    background: #e5e5e5;
}

.topUserMenu ul li a, .topUserMenuMobile ul li a{
   text-decoration: none;
}
.topUserMenu ul li a:visited, .topUserMenuMobile ul li a:visited{
   color:  #000000;
}

.linkTopUser > span:first-child{
    margin-right:  5px;
}

.linkTopUser{
    display: flex;
    justify-content: start;
    align-items: center;
}

.flexLine{
    display: flex;
    align-items: center;
    justify-content: center;
}

.topbarUser >  div:first-child{
    margin-right:  15px;
}

.blackBackground{
    position: fixed;
    background-color: rgba(0,0,0,0.6);
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
}
.modalStabilization{
    width: 100%;
    /*height: 100vh;*/
    position: absolute;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;

}

.wideWhiteBoardModal{
    position: relative;
    padding:30px 25px;
    background-color: #ffffff;
    width: 730px;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2);
    box-shadow: 0 0 8px 0px rgba(34, 60, 80, 0.2);
}

.whiteBlock{
    position: relative;
    padding:30px 25px;
    background-color: #ffffff;
    width: 330px;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2);
    box-shadow: 0 0 8px 0px rgba(34, 60, 80, 0.2);
}

.modalClose{
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: 2px;
    color: rgba(0,0,0,0.6);
    width: 19px;
    height: 19px;
}

.formInputs input[type="text"],.formInputs input[type="password"],.formInputs input[type="email"], .newTextarea textarea, .modalTextArea textarea{
    display: block;

    padding: 8px 16px;
    line-height: 25px;
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
    border-radius: 6px;
    -webkit-appearance: none;
    color: rgba(0,0,0,0.6);
    border: 1px solid rgba(0,0,0,0.3);
    background: #ffffff;
    transition: border .3s ease;
    margin-bottom: 13px;
}

.profileInput{
    width: 329px;
}

.modalInput{
    width: 280px;
}

.modalInputWide{
    width: 580px;
}

.flexLine > div:first-child{
    margin-right: 10px;
}
.flexLine > div:last-child{
    margin-left: 10px;
}
.formInputs input:focus, .newTextarea textarea:focus, .modalTextArea textarea:focus{
    outline: none;
}
.newTextarea textarea{
    max-height: 300px;
    min-height: 100px;
    font-size: 16px;
    max-width: 600px;
    min-width: 300px;
}
.modalTextArea textarea{
    max-height: 300px;
    height: 200px;
    resize: vertical;
    font-size: 16px;

}

.modalTitle, .modalDescription{
    text-align: center;


}
.modalTitle{
    font-weight: 500;
    margin-bottom: 10px;
}

.modalDescription{
    font-weight: 300;
    color: #6f6f6f;
    margin-bottom: 25px;
}

.actionsTests{
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 25px;
}

.actualSale{
    height: 457px;
    position: relative;
    background-position-y: -25px;
}

.actualSaleInformationalBlock{
    padding: 0 30px 30px 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #ffffff;
}
.actualSaleInformationalBlockSale{
    font-size: 24px;
    padding-bottom: 20px;
}

.actualSaleInformationalBlockTitle{
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 20px;
}

.actualSaleInformationalBlockData{
    display: flex;
    justify-content: start;
    align-items: center;
    padding-bottom: 20px;
}

.actualSaleInformationalBlockData span{
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.actualSaleInformationalBlockData span:first-child{
    font-weight: 600;
}
.actualSaleInformationalBlockData span:last-child{
    color: var(--thirdColor);
    font-weight: 600;
}

.actualSaleInformationalBlockDescription{
    line-height: 26px;
}

.saleSmall{
    text-decoration: line-through;
    color: rgba(255,255,255,0.4);
    font-size: 26px;
}

.actualSaleInformationalBlockBuy{
    text-align: right;
}




.lastTrackerInformationBlock>div:first-child{
    text-align: left;
}

.lastTrackerInformationBlock, .mainTestsCatalogSub > div:last-child{


}

.lastTrackerInformation h2{
    color: var(--thirdColor);
    font-size: 14px;
    letter-spacing: 3px;
    font-weight: 600;
    margin-bottom: 1px !important;
}

.lastTrackerInformation a{
    text-decoration: none;
    color: #26222f;
    font-weight: 600;
    font-size: 17px;
}

.lastTrackerInformationItem{
    margin-bottom: 15px;
}

.classicalFlex{
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.mainTestsCatalogSub{
    margin-top: 80px;
}



.leftSideBlock{
    width: 890px;
}
.rightSideBlock{
    width: 280px;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2);
}

.testMore > span:last-child{
    display: flex;
    align-items: center;
    font-weight: 600;
    color: var(--thirdColor);
}

.testMore > span:first-child{
    font-weight: 900;
    font-size: 20px;
    color: #26222f;
}

.testList{
    margin-top: 25px;
    padding-bottom: 60px;
    justify-content: start;
    display:flex;
}

.testListButton, .testListButtonMobile{
    width: 268px;
    padding-bottom: 13px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2);
    text-decoration: none;
    color: #26222f;
}

.testListButtonDesktop{
    margin-right: 40px;

    -webkit-box-shadow: 0px 0px 4px 0px #2e4b86;
    -moz-box-shadow: 0px 0px 4px 0px #2e4b86;
    box-shadow: 0px 0px 4px 0px #2e4b86;
    border: 3px solid #2e4b86;
}

.testListButton:hover{
    -webkit-box-shadow: 0px 0px 10px 0px #2e4b86;
    -moz-box-shadow: 0px 0px 10px 0px #2e4b86;
    box-shadow: 0px 0px 10px 0px #2e4b86;
}

.bottomInsideRelative{
    position: absolute;
    bottom:0;
}

.relativeBlock{
    position: relative;
}

.testListTitle{
    display: block;
    text-decoration: none;
}

.parachuteMain{
    position: fixed;
    transform: translate(-50%, -50%);
    animation: inimate__one 5s infinite;
}

.rocketGif{

}
.mobileParachuteImg{
    width: 65px;
}

.desktopParachuteImg{
    width: 150px;
}

.mobileButtonCorrection{
    font-size: 10px !important;
}


.mobileParachuteBlock{
    right: -8px;
    top: 152px;
}

.desktopParachuteBlock{
    right: 40px;
    top: 240px;
}

@keyframes inimate__one {
    0% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
    50% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(5deg);
    }
}

@keyframes inimate__two {
    0% {
        transform: skew(-20deg) translateX(-200px);
    }
    50% {
        transform: skew(-20deg) translateX(200px);
    }
    100% {
        transform: skew(-20deg) translateX(-200px);
    }
}


.parachuteButton{
    display: flex;
    justify-content: center;

}
.testListTitle, .titleItemOrder{
    font-size: 17px;
    color: #26222f;
    margin-top: 10px;
    padding: 0 10px;
    font-weight: 500;
    text-transform: uppercase;
}
.testListButton > div:first-child{
    background-size: contain;
    width: 261px;
    height: 169px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bottomTestInformation{
    text-align: right;
}
.bottomTestInformation img{
    width:50px;
}

.testCategoryTitle{
    padding: 20px;
}

.testCategoryTitle span{
    padding: 3px 5px;
    border-radius: 2px 2px 2px 2px;
    right: auto;
    left: 0;
    color: #26222f;
    background-color: #fff;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    letter-spacing: 2px;
}

.subscribeSide > div, .testDateInformation, .buyForm{
    border-style: solid;
    border-width: 1px;
    border-color: #dfdee3;
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
    margin: 0;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0 20px 50px 20px;
}

.subscribeSideIconBlock > span{
    color: #ffffff;
    padding: 9px;
    background-color: var(--thirdColor);
    height: 25px;
}

.subscribeSideTitleBlock{
    margin-top: 30px;
    color: #26222f;
    font-size: 20px;
    font-weight: 600;
}
.subscribeSideDescriptionBlock{
    margin-top: 14px;
    margin-bottom: 14px;
    color: #26222f;
    font-size: 14px;
}

.footerLogo{
    padding: 80px 0;
}

.logoBlock a, .logoBlockMobile a{
    display: flex;
    justify-content: start;
    align-items: center;
    text-decoration: none;
}
.logoBlock span{
    font-size: 26px;
    font-weight: 600;
    color: #26222f;
    margin-left: 11px;
}
.icons {
    margin-top: 20px;
}
.icons a{
    margin-right: 10px;
    color: var(--thirdColor);
}

.testDateInformation table{
    width: 100%;
    color: #26222f;
    border-spacing: 0;
    border-collapse: separate;
}

.testDateInformation td{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #dfdee3;
}

.testDateInformation tr > td:first-child{
    font-weight: 500;
}

.testDateInformation th{
    padding: 13px 0;
}

.red{
    color: var(--thirdColor);
}

.buyCard{
    background-color: #ffffff;
    margin-top: 80px;
    color: #26222f;
    padding-bottom: 40px;
}

.bottomAskMobile .modalInput {
    width: 100%;
}

.buyCardInside{
    padding: 50px 227px;
}

.buyCard p{
    margin-bottom: 10px;
    line-height: 27px;
}

.buyCard h2{
    margin-top: 40px;
}

.buyForm{
    margin-top: 30px;
}


label.custom-radio-button input[type="radio"] {
    opacity:0;
}

label.custom-radio-button input[type="radio"] ~ .helping-el {
    background-color: #FFFFFF;
    border: 2px solid #26222f;
    border-radius: 50%;
    display: inline-block;
    margin-right: 7px;
    padding: 11px;
    position: relative;
    top: 3px;
}

label.custom-radio-button input[type="radio"]:checked ~ .helping-el {
    border: 2px solid #26222f;
}

label.custom-radio-button input[type="radio"]:checked ~ .helping-el:after {
    background-color: #26222f;
    border-radius: 50%;
    content: " ";
    font-size: 33px;
    height: 16px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 16px;

}

.buyFormRadioButtons{
    display: flex;
    flex-direction: column;
    margin-top: 55px;
}

.label-text{
    font-weight: 500;
}

.custom-radio-button{
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
}
.label-description{
    font-size: 11px;
}

.helping-el:hover{
    cursor: pointer;
}

.totalPrice{
    font-weight: 600;
    font-size: 20px;
}

.payBlock{
    text-align: right;
}

.buyForm h2{
    text-align: center;
}

.totalPriceBlock{
    padding-left: 25px;
}

.priceWithSale{
    font-size: 20px;
    color: var(--thirdColor);
    font-weight: 500;
}
.through{
    text-decoration: line-through;
}

.hide{
    display: none;
}

.show{
    display: none;
}
.thankYou{

    font-size: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 300px;

}

.thankYou div{
    font-family: Inter, Riwaya, serif;
    color: #4c4c4c;
}

.arrowsDown img{
    width: 40px;
    margin-bottom: -51px;
    margin-left: 15px;
    margin-right: 15px;
}
.arrowsDown{

}

.saleFont{
    font-size: 14px;
}

.lightColor{
    color: rgba(0,0,0,0.8);
}

.imageBuyForm{
    width: 747px;
    margin-bottom: 30px;
}

.registrationLinkModal{


}

.registrationLinkModal:hover{
    cursor: pointer;
}

.inputDivWithMessage{
    color: var(--thirdColor);
    font-size: 13px;
    text-align: right;


    height: 64px;
}

.loginModalBottomLinksBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    margin-top: 20px;

}

.inputDivWithMessage > input{
    margin-bottom: 0;
}

.redBorder{
    border-color: var(--thirdColor) !important;
}

.react-international-phone-input{
    color: rgba(0,0,0,0.6) !important;
    margin-bottom: 13px !important;
    border-bottom-right-radius: 6px !important;
    border-top-right-radius: 6px !important;
    width: 100% !important;
    height: 43px !important;
    border-color: rgba(0,0,0,0.3) !important;
}

.react-international-phone-country-selector-button{
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important;
    height: 43px !important;
    border-color: rgba(0,0,0,0.3) !important;
}

.horizontalLoader{
    text-align: center;
    margin-top: 20px;
}

.horizontalLoader img{
    width: 180px;
}

.successColor{
    color: #3cb879;
}
.errorColor{
    color: #dc3545;
}

.modalIcon{
    width: 70px !important;
    height: 70px !important;
}

.successMessageSmall{
    font-size: 12px;
    margin-bottom: 10px;
}

.successMessage{
    text-align: center;
}

.successMessage h2{
    margin-bottom: 20px;
    margin-top: 0;
    font-size: 25px;

}

.flexCenterCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}

.smallSpinLoader{
    width:20px;

}

.formInputs{
    text-align: center;
}

.buttonLink{
    text-decoration: none;
}

.successMessageForMobile{
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 10px;
}

.topUserMenu a, .topUserMenuMobile a {
    color: #000000;
}

.profileCenter{
    display: flex;
    justify-content: center;

}

.rounded-circle-profile{
    border-radius: 50%;
    margin-bottom: 10px;
    width: 115px;
    height: 115px;
    background-size: cover;
    background-position: center;
}

#file-input{
    display: none;
}

.rounded-circle-profile:hover{
    cursor: pointer;
}

.addPhoto{
    display: flex;
    justify-content: center;
}

.topBarImageAvatar{
    border-radius: 50%;
    margin-right: 20px;
    width: 20px;
    height: 20px;
    background-size: contain;
}



.testListPanelList{
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
}
.testItemInPanel{
    width: 215px;
    margin-right: 30px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 10px;
}

.testItemInPanelPicture{
    width: 215px;
    height: 139px;
    background-size: contain;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.testItemInPanelButtons > button, .testItemInPanelButtons > a{
    width: 45%;
    height: 30px;
}

.testItemInPanelButtons{
    display: flex;
    justify-content: space-between;
}

.testItemInPanelArchive{

}


.buyCardInsidePanel{

}
.buyCardInsidePanel > div{
    padding: 30px 40px;
}

.testListPanelRight{
    width: 160px;
}

.testListPanelLeft{
    width: 850px;
}

.testItemInPanelArchive{
    border-left: 4px solid #9e9e9e;
    border-bottom: 1px solid #999999;
    padding: 5px;
    position: relative;
}

.testItemInPanelArchive:hover{

    border-left: 4px solid #ff4a3f;
}
.testItemInPanelArchive > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.testItemInPanelArchiveMenu{
    color: #9e9e9e;
}

.testItemInPanelArchiveMenu:hover{
    cursor:pointer;
}

.archiveItemMenu{
    position: absolute;
    background-color: #ffffff;
    width: 120px;
    right: 0;
    top: 40px;
    padding: 10px;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.archiveItemMenu > button, .archiveItemMenu > a{
    width: 100%;
    height: 24px;
    margin-bottom: 10px;
}

.archiveItemMenu > a, .linkAsButton{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.modalButtons{

    height: 42px;
}

.redButton, .greenButton, .infoButton, .greyButton, .primaryButton{
    border: unset;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
}

.greyButton{
    background-color: #5c636a;
}

.greenButton{
    background-color: #3cb879;
}

.redButton{
   background-color: var(--thirdColor);
}
.redButton:hover, .greenButton:hover, .infoButton:hover{
    cursor: pointer;
}

.redButton:disabled, .greenButton:disabled, .infoButton:disabled {
    background-color: #bfbfbf;
    color: linen;
    opacity: 1;
    cursor: unset;
}


.infoButton{
    background-color: #17a2b8;

}

.descriptionBeforeService{

}

.statusLine{
    margin-top: 20px;
    width: 300px;
    height: 15px;

}

.statusLineInside{
    height: 5px;
    background-color: #17a2b8;
    display: flex;
}

.rightArrowLoad{
    margin-top: 10px;
}

.statusLineFirstLine{
    width: 39%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.statusLineFirstLine > div:last-child{
    margin-top: -6px;
}

.statusLineSecondLine span{

    color: #ffffff;
    font-size: 12px;
    padding: 7px;
    border-radius: 45%;

}



.waitingLine, .waitingSpan span{
    background: #bfbfbf;
}

.doneLine, .doneSpan span{
    background: #17a2b8;
}

.statusLineSecondLine{
    margin-top: -9px;
}

.finishLoad{
    margin-top: 1px;
}

.firstLoadBlock{
    width: 23px;
    background: #fff;
}

.centralBlock{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.documentUpload{
    text-align: center;
}

.documentUpload:hover{
    cursor: pointer;
}

.littleH{
    margin-bottom: 6px;
    display: inline-block;
    font-size: 12px;
}

textarea:disabled{
    background-color: #e7e7e7;
}

.pleaseAwaitAnswer{
    border-radius: 6px;
    -webkit-appearance: none;
    color: rgba(0,0,0,0.6);
    border: 1px solid rgba(0,0,0,0.3);
    padding: 20px;
    font-size: 17px;
    text-align: center;
    line-height: 1.5;
    width: 100%;
}

.messageForIcon{
    font-size: 12px;
    margin-top: -20px;
}

.downloadBlock:hover{
    cursor: pointer;
}
.answerBlock{
    margin-top: 60px;
    text-align: left;
}

.cancelButton{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 11px;
    border-radius: 5px;
}

.cancelButtonBlock{
    display: flex;
    justify-content: center;
    margin-top:10px;
}

.buttonBefore{
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.centralLoader{
    justify-content: center;
}

.linkAsButtonNew{
    border-radius: 4px;
    text-decoration: none;
    padding: 5px 10px;
}

.testItemInPanelInside{
    padding:10px;
}

.additionInformationForItem ul{
    list-style-type: none;
    padding: 0;
}

.additionInformationForItem li{
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.additionInformationForItem li span{
    font-size: 14px;
    font-weight: 600;
    color: #545454;
    margin-left: 7px;
}

.testItemInPanelButtons a{
    border-radius: 6px;
}

.testItemInPanel:hover{
    box-shadow: 0px 0px 26px 0px rgba(34, 60, 80, 0.2);
}

.warningBlock{
    background-color: #f3dede;
    border-color: #e4cbcd;
    border-radius: 5px;
    border-width: 1px;
    color: #af3e44;
    text-align: center;
    padding: 10px;
    line-height: 25px;
    margin: 30px 0;
}

.roundedButton{
    height: 30px;
    border-radius: 6px;
}

.warningBlock button{
    margin: 20px 0 10px 0;
}

.modalDescription a{
    font-size: 20px;
    color: #6f6f6f;
}

.mainPageSearchBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.mainPageSearchBlockInside{
    background: #ffffff;
    border-radius: 10px;
    padding: 24px 47px;
    text-align: center;
    margin-top: 76px;
}

.SearchBar{
    width:300px;

}

.startTestMessageBlock{
    display: flex;
    justify-content: center;
    margin: 140px 0;
}
.startTestMessage{
    padding: 20px 70px;
    border-style: solid;
    border-width: 1px;
    border-color: #dfdee3;
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
    margin: 0;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
}

.centerLine{
    text-align: center;
}

.my_results_line{
    /*display: flex;
    justify-content: space-between;

     */
}
.my_results_line > div{
   /* width: 47%;

    */
}

.my_results_line_list{
    padding-top: 32px;
}

.my_results_detail{
    border: 1px solid #cbcbcb;
    padding: 15px;
    border-radius: 3px;
    margin-top: 100px;
}

.my_results_detail h2{
    font-size: 18px;
}
.my_results_detail h3{
    font-size: 16px;
}

.fake_link{
    cursor: pointer;
    color: #0c3981;
}

.accordion {
    margin: auto;

}
.accordion .accordionInput {
    display: none;
}
.box {
    position: relative;
    background: white;
    height: 64px;
    transition: all .15s ease-in-out;
}
.box::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    box-shadow: 0 -1px 0 #e5e5e5,0 0 2px rgba(0,0,0,.12),0 2px 4px rgba(0,0,0,.24);
}
header.box {
    background: var(--thirdColor);
    z-index: 100;
    cursor: initial;
    box-shadow: 0 -1px 0 #e5e5e5,0 0 2px -2px rgba(0,0,0,.12),0 2px 4px -4px rgba(0,0,0,.24);
}
header .box-title {
    margin: 0;
    font-weight: normal;
    font-size: 16pt;
    color: white;
    cursor: initial;
}
.box-title {
    width: calc(100% - 40px);
    height: 64px;
    line-height: 64px;
    padding: 0 20px;
    display: inline-block;
    cursor: pointer;
    color: rgba(0,0,0,.74);
    font-weight: 700;
    -webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;
}
.box-content {
    width: calc(100% - 40px);
    padding: 12px 20px;
    font-size: 11pt;
    color: rgba(0,0,0,.74);
    display: none;
}
.box-close {
    position: absolute;
    height: 64px;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    display: none;
}
.accordionInput:checked + .box {
    height: auto;
    margin: 16px 0;
    box-shadow: 0 0 6px rgba(0,0,0,.16),0 6px 12px rgba(0,0,0,.32);
}
.accordionInput:checked + .box .box-title {
    border-bottom: 1px solid rgba(0,0,0,.18);
}
.accordionInput:checked + .box .box-content,
.accordionInput:checked + .box .box-close {
    display: inline-block;
}
.arrows section .box-title {
    padding-left: 44px;
    width: calc(100% - 64px);
}
.arrows section .box-title:before {
    position: absolute;
    display: block;
    content: '\203a';
    font-size: 18pt;
    left: 20px;
    top: -2px;
    transition: transform .15s ease-in-out;
    color: rgba(0,0,0,.54);
}
.accordionInput:checked + section.box .box-title:before {
    transform: rotate(90deg);
}

.accordionButton{
    margin-left: 12px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100px;
}

.linkInsideAccordion{
    display: flex;
    align-items: center;
}

.questionBlock{
    padding: 20px 30px;
   /* -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    */
}

.timerPlace{
    text-align: right;
    padding: 15px;
    font-size: 25px;
}

.boxShadow{
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
}

.questionDescriptionSide p{

    /*font-size: 18px;*/
    font-family: Inter, Riwaya, serif;
    color: #4c4c4c;
}

.questionDescriptionSide img{
    max-height: 300px;
}

.wrapper2{
    width: 90%;
    margin: 0 auto;
}

.fakeBackground{
    background-color: #d9d9d9;
    padding: 60px 0;
}
.questionTitle{
    font-family: Inter, Riwaya, serif;
    font-size: 18px;
    margin-top: -36px;
}

.questionDescriptionSide{
    text-align: center;
    margin-top: 40px;
}

.questionDescriptionSide img{
  /*  width: 285px;*/
}

.questionDivider{
    width: 65%;
    height: 7px;
    background-color: #f4f4f4;
    margin: 45px auto;
}

.questionAnswersSide{
    display: flex;
    justify-content: center;

}

.questionAnswersSide img{
    width: 130px;
}

.questionAnswersSide span{
    font-size: 30px;
    margin-right: 10px;
}

.questionAnswersSide div{
    margin: 0 10px;
    display: flex;
    align-items: center;
}

.imageOptionSelect{
    border: 2px solid #dedede;
    border-radius: 5px;
}

.imageOptionSelectResultWrong{
    border: 2px solid var(--thirdColor);
    border-radius: 5px;
}
.imageOptionSelectResultCorrect{
    border: 2px solid #3CB878;
    border-radius: 5px;
}

.priceLine{
    margin-top: 10px;
}

.checkMainBlock{
    position: relative;
}

.checkBlock{
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    width: 100%;
    min-width: 800px;
    padding: 20px;
    z-index: 2;
    position: relative;
    background: #fff;
}

.checkBlockBackground{
    background-image: url("/public/img/checkBackground.webp");
    background-size: cover;
}
.paymentTable {
    width: 100%;
}
.paymentTable td:nth-child(1) {
    font-weight: bold;
    width: 13%;
}

.paymentTable td{
    padding: 10px;
}

.paidStampBlock{
    text-align: right;
}

.paidStampBlock img{
    width: 180px;
    margin-top: -64px;
}

.paymentTable td:nth-child(2) {
      width: 87%;
}

.paymentTable, .paymentTable th, .paymentTable td {
    border: 1px solid;
}
.paymentTable td {
    width: 100%;
}
.checkBlockCurved::before{
    content: "";
    position: absolute;
    background: linear-gradient(to left, #222, #ccc);
    width: 60%;
    height: 60%;
    transform: skewY(-8.5deg);
    transform-origin: right;
    bottom: 15px;
    left: 15px;
    border-radius: 10px;
    filter: blur(5px);
    opacity: .7;
    z-index: 0;
}
.checkBlockCurved::after{
    content: "";
    position: absolute;
    background: linear-gradient(to right, #222, #ccc);
    width: 60%;
    height: 60%;
    transform: skewY(8.5deg);
    transform-origin: left;
    bottom: 15px;
    right: 15px;
    border-radius: 10px;
    filter: blur(5px);
    opacity: .7;
    z-index: 0;
}

.imageOptionSelect:hover{
    cursor: pointer;
    border: 2px solid var(--thirdColor);
}

.selectedAnswer{
    border: 2px solid #30bdfa !important;
}

.bottomInformationLine{
    padding: 20px 0;
}

.bottomInformationLineCenter{
    padding: 17px 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bottomInformationLineCenter span{
    color: #595959;
}
.nextQuestionButtonBlock, .testNumberLineMainBlock{
    display: flex;
    justify-content: center;
}
.nextQuestionButtonBlock button{
    margin: 0 10px;
}

.testNumberLineMainBlock{
    padding: 35px 0;
}

.testNumberLineMainItemIndicator{
    height: 3px;
    background-color: #8b8b8b;

}

.testNumberLineMainItem{
    width: 20px;
    text-align: center;
    margin: 0 5px;
}

.testNumberLineMainItem:hover{
    cursor: pointer;
}

.bigButton{
    font-size: 18px;
    height: 45px;
    padding: 0 25px;
}

.descriptionBigFont{
    font-size: 23px;
}

.textOption{
    background-color: #efefef;
    padding: 5px 20px;
    border-radius: 5px;
    margin-bottom: 15px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    align-items: center;
    display: flex;
    border: 2px solid #efefef;
}

.textOptionResult{
    background-color: #efefef;
    padding: 5px 20px;
    border-radius: 5px;

  /*  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);

   */
    align-items: center;
    display: flex;
    border: 2px solid #efefef;
}

.textOptionResultCorrect{
    background-color: #3CB878 !important;

    box-shadow: 0px 0px 8px 0px rgba(60,184,121, 0.2)!important;

    border: 2px solid #3cb879!important;
}

.textOptionResultWrong{
    background-color: var(--thirdColor) !important;

    box-shadow: 0px 0px 8px 0px rgba(229,75,66, 0.2) !important;

    border: 2px solid var(--thirdColor) !important;
}

.textOption:hover{
    cursor: pointer;
}

.questionAnswersTextSide{
    margin: 0 auto;
    width: 400px;
}

.textOption span, .textOptionResult span{
    font-size: 27px;
    font-weight: 500;
    margin-right: 10px;
}

.textOption:hover{
    border: 2px solid var(--secondColor) !important;
}
.selectedOption{
    background-color: var(--secondColor);
    -webkit-box-shadow: 0px 0px 8px 0px rgba(48, 189, 250, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(48, 189, 250, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(48, 189, 250, 0.2);
    border: 2px solid var(--secondColor) !important;
    color: #ffffff;
}

.twoSidedQuestionBlock{
    display: flex;
    justify-content: center;
}
.twoSidedQuestionBlock > div{
    margin: 0 20px;
}

.littleQuestionPicture img{
    width: 150px;
}

.middleQuestionPicture img{
    width: 550px;
}

.topBarMainBlock{
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 3px 0px rgba(34, 60, 80, 0.2);
}

.spanResult {
display: flex;
    justify-content: start;
    align-items: center;
    max-width: 300px;
    padding: 10px 5px;
    cursor: pointer;
    font-size: 17px;
    color: #535353;
}

.SearchBar .wrapper > div:nth-child(even) svg {
    display: none !important;
}

.spanResult img{
    width:50px;
    margin-right: 5px;
    border-radius: 5px;
}

.spanResult div{
    margin-top: 6px;

    box-sizing: content-box;
    white-space: break-spaces;
}
.spanResultMobile div{
    white-space: break-spaces;
}



.testItemInPanelInsideMain{
    display: flex;
    flex-direction: column;
}


/* MOBILE */

.wrapperMobile{
    max-width: 100%;
}
.mobileCenter{
    display: flex;
    justify-content: center;
    width: 100%;
}

.testListButtonMobile{
    margin-bottom: 20px;
    margin-top: 20px;
}

.articleIcon{
    width: 30px;
}

.wrapperInsideMobile{
    width:90%;
    margin: 0 auto;
}

.ArticlesListMobile{
    margin-top: 40px;
}

.selectCurrencyBlock{
    border-color: #c7c7c7;
    border-width: 1px;
    border-radius: 3px;
    outline: #c7c7c7;
    padding: 4px;
    font-weight: 500;
}

.selectCurrencyBlock:focus, .selectCurrencyBlock[data-chosen]{
    border-color: #c7c7c7;
    border-width: 1px;
}

.imageBuyFormMobile{
    width:100%;
    margin-bottom: 20px;
}

.testListPanelListMobile{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box-content-mobile{
    width: 100% !important;
}

.footerLogo{
    display: flex;
    align-items: center;
}

.contactLink{
    padding: 0 20px;
    display: flex;
    flex-direction: column;
}

.linkInsideAccordionMobile{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.linkToBack{
    font-weight: 500;
    text-decoration: none;
    color: var(--thirdColor);
}

.linkToBackBlock{
    text-align: right;
    margin-bottom: 45px !important;
}

.acceptOfferCenter{
    display: flex;
    justify-content: center;
    margin-top: -40px;
}

.questionAnswersSideMobile{
    flex-direction: column;
}

.questionAnswersSideMobile > div{
    margin-bottom: 20px;
}

.mobileAnswers{
    margin-top: 50px;
    display: block;
}

.bottomInformationLineCenterMobile{
    margin-top: 53px;
    flex-direction: column;
}

.questionDescriptionSideMobile img{
    max-width: 100%;
}

.questionAnswersTextSideMobile > div{
    margin-bottom: 25px !important;
}
.testNumberLineMainBlockMobile{
    flex-wrap: wrap;
}

.testNumberLineMainItemMobile{

    width: 35px;
    text-align: center;
    margin: 5px;

}

.bottomMobileBarMain{
    position: fixed;
    bottom: 0;
    width:100%;

}

.box-title:hover{
    cursor:pointer;
}

.bottomMobileBarInside{
    width: 100%;
    height: 50px;
    background: #ffffff;
    border-top-left-radius: 33px;
    border-top-right-radius: 33px;
    -webkit-box-shadow: 0px -7px 12px -4px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px -7px 12px -4px rgba(34, 60, 80, 0.2);
    box-shadow: 0px -7px 12px -4px rgba(34, 60, 80, 0.2);
}

.hideIconFormMobileBarBlock{

}

.hideIconFormMobileBar{
    position: absolute;
    right: 10px;
    margin-top: -31px;
    width: 23px;
}

.bottomMobileBarWrapper{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 50px;
    width: 97%;
}

.bottomMobileBarImageAvatar{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-size: contain;
}

.testTitleTop{
    text-align: center;
    padding: 0 0 30px 0;
    font-weight: 500;
    font-size: 17px;
    color: #444444;
}

.bottomMobileBarButton{
    padding: 0 8px;
    font-size: 12px !important;
}

.bottomMobileBarHome{
    width: 35px !important;
    height: 35px !important;
    color: #535353;
}

.logoBlockMobile span{
    font-weight: 600;
    color: #26222f;
    margin-left: 11px;
}

.logoMobile{
    width: 100px;
}

#app-lang-mobile-btn{
    height: 27px;
}

.topUserMenuMobile{
    position: absolute;
    bottom: 52px;
    right: 7px;
    z-index: 999999;
    border: 1px solid #bdbbbb;
    border-radius: 3px;
    background: #ffffff;
    padding: 8px 0;
    max-height: 180px;
    overflow: auto;
    color: #000000;
}

.profileInputMobile{
    width: 100%;
}

.profileCenterMobile{
    padding: 30px 0;
}

.questionResultBlock{
   /* -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);

    */
    padding: 15px;
    border-radius: 5px;
    width: 100%;
}
.buyCardInsideWide{
    padding: 20px 25px 20px 15px;
}

.questionResultBlockMain{
    margin-bottom: 43px;
    display: flex;
    justify-content: start;
    align-items: center;

}

.questionResultBlockMain > div:first-child{
    font-weight: bold;

    font-size: 38px;
    margin-right: 20px;
    width: 50px;
}

.questionResultBlockMainMobile > div:first-child{
    font-weight: bold;
    color: #a3a3a3;
    font-size: 38px;
    margin-top: 20px;
    text-align: center;
}

.newDescription .descriptionBigFont{
    font-size: 16px;
}



.questionResultBlock h3{
    font-size: 19px;
}

.textOptionResultMain{
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 15px;
}
.textOptionResultMain >div{
    width: 100%;
}

.resultIcon{
    width: 40px !important;
    height:40px !important;
    margin-left:10px;

}
.resultIconPicture{
    width: 40px !important;
    height:40px !important;
    margin-left: 35px;

}


.correctIcon{
color: #3CB878;
}

.wrongIcon{
color: var(--thirdColor);
}

.imageOptionSelectResultBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 10px;
}

.questionAnswersSideResult{
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageOptionSelectResultBlock> div:first-child{
    display: flex;
    justify-content: center;
    align-items: center;
}

.newDescription img, .newExplanation img{
    max-width: 300px;
}

.imageOptionSelectResultBlock img{
    max-width: 100px;
}

.imageOptionSelectResultBlock span{
    font-size: 26px;
    margin-right: 10px;
}

.twoSidedQuestionBlockResult{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.headerForSideName{
    font-size: 13px;
}

.questionAnswersSideResultMobile{
    flex-direction: column;
}
.questionAnswersSideResultMobile > div{
    margin-bottom: 15px;
}

.greenBorder{
    border: 1px solid #3CB878 ;
}

.redBorder{
    border: 1px solid var(--thirdColor) ;
}
.grayBorder{
    border: 1px solid #919191 ;
}

.noAnswers{
    color: var(--thirdColor);

    margin-left: 15px;
}

.bigResultBlock{


}

.redShadow{
    box-shadow: 0px 0px 8px 0px rgba(229, 75, 66, 0.2);
    -webkit-box-shadow: 0px 0px 8px 0px rgba(229, 75, 66, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(229, 75, 66, 0.2);
}
.grayShadow{
    box-shadow: 0px 0px 8px 0px rgba(145, 145, 145, 0.2);
    -webkit-box-shadow: 0px 0px 8px 0px rgba(145, 145, 145, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(145, 145, 145, 0.2);
}
.greenShadow{
    box-shadow: 0px 0px 8px 0px rgba(60, 184, 120, 0.2);
    -webkit-box-shadow: 0px 0px 8px 0px rgba(60, 184, 120, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(60, 184, 120, 0.2);
}

.imageOptionSelectResultStandard{
    border:2px solid #dedede;
    border-radius: 5px;

}
.iconStub{
    width: 41px;
    height: 41px;
}

.aloneButtonCenter{
    display: flex;
    justify-content: center;
    padding: 35px 0;
}

.bigResultBlockTop{
    text-align: center;
    border: 1px solid #bebebe;
    border-radius: 10px;
    margin: 15px 0;
}

.numberCorrectAnswers{
    font-size: 50px;
    margin-top: 27px;
}

.pieBlock{
    display: flex;
    justify-content: space-around;
    padding: 30px 0;
}

.pie{
    width: 300px;
}

.resultIconPictureEmpty{
    width:100px;
    height:40px;
}

.numberQuestionCorrect{
    color: rgba(60, 184, 120, 0.5);
}

.numberQuestionWrong{
    color: rgba(229, 75, 66, 0.5);
}

.percentageOfCorrects{
    font-size: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #6bc8ff;
}

.resultTitleTopBlock{
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.extraTitles{
    font-size: 12px;
}

.tableResultP{
    color: #18a4f6;
    font-weight: 700;
    font-size: 15px;
}

.shortIcon{
    width: 50px;
}

.successSmall{
    font-size: 12px;
}
.percentageBlock{
    display: flex;
    flex-direction: column;
}

.textAlignRight{
    text-align: right;
}
.redColor{
    color: var(--thirdColor);
}
.yellowColor{
    color: #ffaa00;
}

.overflowScrollX{
    overflow-x: scroll;
}

.beginnerColor{
    color: #c68585;

}
.elementaryColor{
    color: #e1b60d;
}

.preIntermediateColor{
    color: #33b733;
}

.intermediateColor{
    color: #e14c4c;
}

.upperIntermediateColor{
    color: #1f76df;
}

.advancedColor{
    color: #a133b7;
}

.linkAsButtonSimple{
    padding: 5px 10px;
    text-decoration: none;
}

.articleBlock{
    color: rgba(0,0,0,0.88);
    line-height: 29px;
    font-family: Roboto, sans-serif;
    font-size: 18px;

}

.articleBlock h1{
    font-family: Inter, Riwaya, serif;
    color: #4c4c4c;
    font-size: 46px;
    text-align: center;
}

.outerUlStyle > li{
    margin-bottom: 18px;
}
.linkBottomForArticles{
    display: flex;
    justify-content: center;
    font-weight: 600;
}
.linkBottomForArticles a{
    color: rgb(63 122 218);
}

.blockForGetAccess{
    text-align: center;
    margin-bottom: 35px;
    margin-top: 36px;

}

.linkBottomForArticles > div{
    margin: 0 20px;
}

.outerUlStyle > li::marker{
    content: '✔ ';
    font-size: 20px;
}

.tableCat4Levels th {
    font-family: Lato-Bold;
    font-size: 18px;
    color: #fff;
    line-height: 1.4;

    background-color: #6c7ae0;
}

.tableCat4Levels td {
    font-family: Lato-Regular;
    font-size: 15px;
    color: #808080;
    line-height: 1.4;
}

.tableCat4Levels .tableCat4Levels tr:nth-child(even) {
    background-color: #f8f6ff;
}

/*---------------------------------------------*/

.tableCat4Levels {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
}

.tableCat4Levels .ps__rail-y {
    right: 5px;
}

.tableCat4Levels .ps__rail-y::before {
    background-color: #ebebeb;
}

.tableCat4Levels .ps__rail-y .ps__thumb-y::before {
    background-color: #cccccc;
}
.articleMainBlock{
    padding: 0 20px;
}

.questionDescriptionSideArticle{
    text-align: center;
}

.questionDescriptionSideArticle img{
    max-width: 300px;
}

.bottomAsk img {
    width: 300px;
}

.bottomAskMobile{
    margin-top: 30px;
}

.bottomAsk{
    display: flex;
    align-items: center;
    margin-top: 30px;
}


.bottomAskHeader{
    font-weight: 600;
    margin-bottom: 7px;
}

.bottomAskTwoParts{
    display: flex;
    justify-content: space-between;
}

.bottomAskTwoParts >div:first-child{
    margin-right: 10px;
}

.articleMainBlock h2{

    margin-bottom: 18px;
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 30px;
    line-height: 1.4;
    font-weight: 700;
    letter-spacing: 0em;
}

.forExample{
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    margin-top: 31px;
}

.mt50{
    margin-top: 50px !important;
}
.mt100{
    margin-top: 100px !important;
}

.linkProductBlock{
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid rgba(0,0,0,0.4);
    margin-top: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
}
.linkProductBlock a{
    margin-bottom:6px;
}

.bottomM{
    padding-bottom: 20px;
    padding-top: 20px;
}

.mtm42{
    margin-top: -42px !important;
}

.serverErrorBlock{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}

.loaderBlockMain{
    position: fixed;
    height: 100vh;
    top: 0;

    width: 100%;
    background: rgba(255,255,255,0.5);
}

.loaderBlockMainInside{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.centralErrorBlock{
    text-align: center;
}

.noProductsMainBlock{
    text-align: center;
}
.noProductsMainBlock p:first-child{
    font-size: 21px;
}
.noProductsMainBlockToCatalog{
    margin-top: 16px;
}

.noProductsMainBlock p:last-child{
    font-size: 18px;
}

.smallLeftTitle{
    font-weight: 900;
    font-size: 20px;
    color: #26222f;
}

@media (max-width: 420px) {
    .twoSidedQuestionBlock > div{
        margin: 0 10px;
    }
}
@media (max-width: 330px) {
    .textOption {
        padding: 5px 10px;
    }
}